
import image from '@images/examples/kaarsje-resormeren.png'
import {relativeRoute} from "@/helpers/relativeRoute";

export default function Onboarding() {
  return <section className="card">
    <div>
      <img src={image} alt=""/>
      <div>
        <h2>Overlijden bekendmaken</h2>
        <p>Stel eenvoudig een rouwadvertentie op die je kunt laten plaatsen in vrijwel alle kranten:
          landelijke dagbladen, regionale nieuwsbladen en huis-aan-huis bladen.</p>
        <a className="button" href={"https://www.familieberichten.nl/home"} target={'_blank'}>Rouwadvertentie opstellen</a>

      {/*  Na livegang digital only*/}
      {/*  <p>Maak een overlijden bekend via een online rouwpagina of via een rouwadvertentie in de krant.</p>*/}

      {/*  <a className="button" href={relativeRoute('create')}>Online rouwpagina opstellen</a>*/}
      {/*  <a className="button" href="https://www.familieberichten.nl/home" target="_blank">Rouwadvertentie opstellen</a>*/}

      </div>

    </div>

  </section>

}
